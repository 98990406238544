<template>
  <BaseInput
    :id="id"
    :label="label"
    :placeholder="placeholder"
    :modelValue="text"
    @update:modelValue="changeValue"
    :list="'datalist-' + id"
    v-bind="$attrs"
    @blur="validateValue"
    @focus="$emit('update:modelValue', null)"
  />
  <datalist :id="'datalist-' + id">
    <option
      :key="'datalist-' + id + 'option-' + key"
      v-for="(option, key) in options"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </datalist>
</template>

<script>
import { defineComponent, nextTick, computed } from 'vue'
import BaseInput from './BaseInput.vue'

export default defineComponent({
  components: {
    BaseInput
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    async function changeValue(value) {
      context.emit('update:modelValue', isNaN(value) ? value : parseInt(value))
      await nextTick()
      document.activeElement.blur()
    }
    function validateValue() {
      if (!props.options.find(option => option.value == props.modelValue)) {
        context.emit('update:modelValue', null)
      }
    }
    const text = computed(
      () => props.options.find(option => option.value == props.modelValue)?.text
    )

    return {
      validateValue,
      text,
      changeValue
    }
  }
})
</script>
