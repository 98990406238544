<template>
  <Label :label="radioGroup.label" />
  <BaseRadio
    v-for="radio in radioGroup.radios"
    :key="radio.id"
    :value="radioGroup.value"
    :name="radioGroup.name"
    v-model="radio.value"
    :id="radio.id"
    :label="radio.label"
    :placeholder="radio.placeholder"
    :required="radio.required"
    :type="radio.type"
    @update:modelValue="value => $emit('update:modelValue', value)"
  />
</template>

<script>
import Label from './Label'
import BaseRadio from './BaseRadio'
export default {
  components: { Label, BaseRadio },
  props: {
    radioGroup: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number]
    }
  }
}
</script>
