<template>
  <form @submit.prevent="onSubmit">
    <slot name="elements"></slot>
    <Button :isDisabled="isLoading" :isLoading="isLoading" type="submit">
      {{ isLoading ? 'Procesando' : 'Guardar' }}
    </Button>
  </form>
</template>

<script>
import Button from '@/components/common/button/Button.vue'

export default {
  components: {
    Button
  },
  props: {
    formElements: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSubmit() {
      let data = {}
      this.formElements.forEach(element => {
        data[element.data.id] =
          element.data.value !== undefined ? element.data.value : null
      })
      this.$emit('form:onSubmit', data)
    }
  }
}
</script>
