<template>
  <template v-if="isVisible">
    <slot name="before" />
    <!-- Input -->
    <BaseInput
      v-if="
        element.type === 'input' &&
          ['text', 'tel', 'date'].includes(element.data.type)
      "
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
      :type="element.data.type"
    />
    <!-- Input File -->
    <BaseInputFile
      v-if="element.type === 'input' && ['file'].includes(element.data.type)"
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
      :type="element.data.type"
    />

    <!-- Datalist -->
    <BaseDatalist
      v-if="element.type === 'datalist'"
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
      :options="element.data.options"
    />
    <!-- Checkbox -->
    <BaseCheckbox
      v-if="element.type === 'input' && element.data.type === 'checkbox'"
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
      :type="element.data.type"
    />

    <!-- Radio -->
    <BaseRadio
      v-if="element.type === 'input' && element.data.type === 'radio'"
      v-model="value"
      :value="value"
      :name="element.data.name"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
      :type="element.data.type"
    />
    <!-- Select -->
    <BaseSelect
      v-if="['select'].includes(element.type)"
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :required="element.data.required"
      :options="element.data.options"
    />
    <!--Textarea-->
    <BaseTextarea
      v-if="element.type === 'textarea'"
      v-model="value"
      :id="element.data.id"
      :label="element.data.label"
      :placeholder="element.data.placeholder"
      :required="element.data.required"
    />

    <!-- RadioGroup -->
    <BaseRadioGroup
      v-if="element.type === 'radioGroup'"
      :radioGroup="element.data"
      v-model="value"
    />

    <slot name="after" />
  </template>
</template>

<script>
import { computed } from 'vue'
import BaseInput from '@/components/form/elements/BaseInput.vue'
import BaseInputFile from '@/components/form/elements/BaseInputFile.vue'
import BaseDatalist from '@/components/form/elements/BaseDatalist.vue'
import BaseCheckbox from '@/components/form/elements/BaseCheckbox.vue'
import BaseRadio from '@/components/form/elements/BaseRadio.vue'
import BaseRadioGroup from '@/components/form/elements/BaseRadioGroup.vue'
import BaseSelect from '@/components/form/elements/BaseSelect.vue'
import BaseTextarea from '@/components/form/elements/BaseTextarea.vue'
export default {
  components: {
    BaseInput,
    BaseInputFile,
    BaseDatalist,
    BaseSelect,
    BaseCheckbox,
    BaseRadio,
    BaseRadioGroup,
    BaseTextarea
  },
  props: {
    element: {
      type: Object
    },
    elements: {
      type: Array
    },
    modelValue: {
      type: [String, Number, Boolean]
    }
  },
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const isVisible = computed(() => {
      return typeof props.element.isVisible === 'function'
        ? props.element.isVisible(props.elements)
        : true
    })
    return {
      value,
      isVisible
    }
  }
}
</script>
