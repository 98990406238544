<template>
  <component
    class="cursor-pointer"
    :is="computedTagName"
    :href="href"
    :to="to"
    v-bind="$props"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    as: {
      type: [String, Object],
      required: false
    },
    to: {
      type: [String, Object],
      required: false
    },
    href: {
      type: String,
      required: false
    }
  },
  computed: {
    computedTagName() {
      return this.as
        ? this.as
        : this.to
        ? `router-link`
        : this.href
        ? `a`
        : `button`
    }
  }
}
</script>
