<template>
  <InteractiveElement
    class=" text-sm border border-green-500 bg-green-500 text-white rounded-md px-3 py-1  transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline flex items-center"
    :disabled="isDisabled"
  >
    <svg
      v-if="isLoading"
      class="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot />
  </InteractiveElement>
</template>

<script>
import InteractiveElement from '@/components/common/InteractiveElement.vue'

export default {
  components: {
    InteractiveElement
  },
  props: {
    isDisabled: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  }
}
</script>
