<template>
  <label
    class="text-xs inline-flex align-center"
    :class="{ 'font-bold  mb-2': label }"
  >
    <template v-if="label">{{ label }}</template>
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    }
  }
}
</script>
