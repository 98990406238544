export default function(elements, values) {
  return elements.map(element => {
    return {
      ...element,
      data: {
        ...element.data,
        value: values[element.data.id]
          ? values[element.data.id]
          : element.data.value
      }
    }
  })
}
